import { Box } from '@chakra-ui/react';
import { Header } from './header';
import { List } from './list';

export function GradeListing({ data }: any) {
  return (
    <>
      {data?.map((serviceOrder) => {
        return (
          <Box mb="9" key={serviceOrder.id}>
            <Box flex="1" bg="gray.600" p="2" borderRadius="8" as="form">
              <Header orderNumber={serviceOrder.id} demandId={serviceOrder.demand_id} />
            </Box>
            <List serviceOrder={serviceOrder} />
          </Box>
        );
      })}
    </>
  );
}
