/* eslint-disable react/no-children-prop */
import { Box, Flex, Heading, Divider, VStack, SimpleGrid, HStack, Button } from '@chakra-ui/react'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'
import { Input } from '../../../components/Form/Input'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RawMaterial } from '../../../Interfaces/rawMaterial'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '../../../services/api'
import { Select } from '../../../components/Form/Select'
import { useProviders } from '../../../services/hooks/useProviders'
import { SelectOptions } from '../../../Interfaces/selectOptions'
import { useToast } from '@chakra-ui/react'
import { Category } from '../../../Enuns/category.enum'
import { MeasurementUnit } from '../../../Enuns/measurementUnit.enum'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const createuseRawMaterialsSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  amount: yup.string().required('Quantidade obrigatório'),
  price: yup.string().required('Preço obrigatório'),
  inventory_min: yup.string().required('Estque mínimo obrigatório'),
  inventory_max: yup.string().required('Estque máximo obrigatório'),
  measurement_unit: yup.string().required('Estque máximo obrigatório'),
})

const categoryOptions = Object.entries(Category).map((cat) => ({
  label: cat[1],
  value: cat[0],
}))

const unitsOptions = Object.entries(MeasurementUnit).map((unit) => ({
  label: unit[1],
  value: unit[0],
}))

export default function RawMaterialCreateOrUpdade({ match }: any) {
  const [hasCa, setHasCa] = useState(false)

  const toast = useToast()

  const queryClient = useQueryClient()

  const history = useHistory()

  const { id } = match.params

  const isCreateMode = !id

  const { data } = useProviders<SelectOptions[]>(true)

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(createuseRawMaterialsSchema),
  })

  const errors = formState.errors

  if (id) {
    useQuery(
      'raw-material',
      async () => {
        const { data } = await api.get<RawMaterial>(`raw-materials/${id}`)
        reset(data)
        if (data.category === 'EPI') {
          setHasCa(true)
        }
      },
      {
        staleTime: 5000,
      },
    )
  }

  const createRawMaterials = useMutation(
    async (raw_materials: RawMaterial) => {
      const response = await api.post('raw-materials', { ...raw_materials })

      if (response) {
        toast({ description: 'Cadastrado com sucesso', status: 'success' })
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('raw-materials')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const updateRawMaterials = useMutation(
    async (raw_material: RawMaterial) => {
      const response = await api.put(`raw-materials/${id}`, { ...raw_material })

      if (response) {
        toast({ description: 'Atualizado com sucesso', status: 'success' })
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('raw-materials')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleCreateRawMaterial: SubmitHandler<RawMaterial> = async (values) => {
    isCreateMode ? await createRawMaterials.mutateAsync(values) : await updateRawMaterials.mutateAsync(values)

    history.push('/')
  }

  const handleChangeCategory = (value) => {
    value.target.value === 'EPI' ? setHasCa(true) : setHasCa(false)
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateRawMaterial)}>
      <Heading size="lg" fontWeight="normal">
        {isCreateMode ? 'Nova Matéria Prima' : 'Editar Produto'}
      </Heading>
      <Divider my="6" borderColor="gray.700" />

      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Nome do produto" {...register('name')} error={errors.name as FieldError} />
          <Input label="Quantidade" {...register('amount')} error={errors.amount as FieldError} />
          <Input label="Preço" {...register('price')} error={errors.price as FieldError} />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Select
            label="Unidade de medida"
            {...register('measurement_unit')}
            options={unitsOptions}
            error={errors.measurement_unit as FieldError}
            placeholder="Selecione a unidade"
          />
          <Input label="Estoque mínimo" {...register('inventory_min')} error={errors.inventory_min as FieldError} />
          <Input label="Estoque máximo" {...register('inventory_max')} error={errors.inventory_max as FieldError} />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Select
            label="Categoria"
            {...register('category')}
            options={categoryOptions}
            error={errors.category as FieldError}
            onChange={handleChangeCategory}
            placeholder="Selecione a categoria"
          />
          {hasCa && <Input label="CA" {...register('ca')} error={errors.price as FieldError} />}

          <Select
            label="Provedor"
            {...register('provider_id')}
            options={data?.providers as SelectOptions[]}
            error={errors.provider_id as FieldError}
            placeholder="Selecione o provedor"
          />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button as="a" href="/" colorScheme="whiteAlpha">
            Cancelar
          </Button>
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
