import { Box, Flex } from '@chakra-ui/react';
import { getHours } from 'date-fns';
import {ReactChild, useContext, useEffect, useState} from 'react';
import { setInterval } from 'timers';
import { Header } from '../../../components/Header';
import { Sidebar } from '../../../components/Sidebar';
import { ConfigurationContext } from '../../../context/ConfigurationContext';
import { Notifications } from '../../../Interfaces/notifications';
import { api } from '../../../services/api';
import {cookies} from "../../../context/AuthContext";

interface AuxProps {
  children: ReactChild | React.ReactNode;
  openLayout: boolean;
}

export function Default({ children, openLayout }: AuxProps) {
  const { populateNotifications } = useContext(ConfigurationContext);
  useEffect(() => {
      checkNotifications()
      setInterval(checkNotifications, 2000);
  }, []);

  const checkNotifications = async () => {
    if (cookies.get('@AXAuth:Token')) {
      const currentHour = getHours(new Date());

      const storageHour = localStorage.getItem('NOTIFICATIONS');
      if (currentHour !== JSON.parse(storageHour)) {
        const {data} = await api.get<Notifications>('/raw-material-notifications');

        populateNotifications(data);

        localStorage.setItem('NOTIFICATIONS', JSON.stringify(currentHour));
      }
    }
  };

  return (
    <>
      {openLayout ?
        (
          <>
            <Header />
            <Flex w="100%" my="6" mx="auto" maxWidth={1700} px="6">
              <Sidebar />
  
              <Box flex="1" borderRadius="8" bg="gray.800" p="8">
                {children}
              </Box>
            </Flex>
          </>
        )
        :
        (
          <Flex w="100%" maxWidth={1700} >
            <Box flex="1" borderRadius="8" bg="gray.800" p="8">
              {children}
            </Box>
          </Flex>
        )
      }
    </>
  );
}
