import {
  Box,
  Button,
  Checkbox,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Spinner,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  useToast,
  Tag,
} from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { useMutation } from 'react-query'
import { RiAddLine, RiCheckLine, RiDeleteBin6Line, RiPencilLine } from 'react-icons/ri'
import { Modal } from '../../components/Modal'
import { Pagination } from '../../components/Pagination'
import { RawMaterial } from '../../Interfaces/rawMaterial'
import { useRawMaterials } from '../../services/hooks/useRawMaterials'
import { api } from '../../services/api'
import CurrencyFormat from 'react-currency-format'
import { Link } from 'react-router-dom'
import { SearchContext } from '../../context/SearchContext'
import { useEffect } from 'react'
import { Category } from '../../Enuns/category.enum'

export function RawMaterials() {
  const { searchValue } = useContext(SearchContext)
  const [page, setPage] = useState(1)

  const [totalPrice, setTotalPrice] = useState<number>()

  const toast = useToast()

  const { queryContextParams, clearQuery } = useContext(SearchContext)

  const { data, isLoading, isFetching, error, refetch } = useRawMaterials({ page, ...queryContextParams })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentRawMaterial, setcurrentRawMaterial] = useState<RawMaterial>()

  useEffect(() => {
    refetch()
  }, [queryContextParams])

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('raw-materials/totalPrice')

      const total = parseFloat(data.totalPrice)

      if (!isNaN(total)) {
        setTotalPrice(total)
      }
    })()
  }, [isOpen])

  useEffect(() => {
    return () => {
      clearQuery()

      refetch()
    }
  }, [])

  const handleRemoveButton = () => {
    if (currentRawMaterial) removeRawMaterial.mutateAsync(currentRawMaterial)

    onClose()
  }

  const removeRawMaterial = useMutation(async (raw_materials: RawMaterial) => {
    const response = await api.delete(`raw-materials/${raw_materials.id}`)

    if (response) {
      toast({ description: 'Removido com sucesso', status: 'success' })
      refetch()
    }

    onClose()
    return response.data
  })

  const handleModalOpen = (raw_material) => {
    setcurrentRawMaterial(raw_material)

    onOpen()
  }

  const filteredRawMaterial = data?.raw_materials.filter((rawMaterial) => {
    if (!searchValue) {
      return true
    }

    const searchValueLower = searchValue.toString().toLowerCase()

    return rawMaterial.name.toLowerCase().includes(searchValueLower)
  })

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8">
      <Flex mb="8" justifyContent="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Matéria Prima - R${' '}
          <CurrencyFormat
            displayType="text"
            decimalSeparator="."
            thousandSeparator=","
            value={totalPrice?.toFixed(2)}
          />
          {!isLoading && isFetching && <Spinner size="sm" color="gray.500" ml="4" />}
        </Heading>

        <Button
          as={Link}
          to="/raw-materials/create"
          size="sm"
          fontSize="sm"
          colorScheme="pink"
          leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
          Criar Novo
        </Button>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Flex justify="center">
          <Text>Falha ao obter os matérias primas</Text>
        </Flex>
      ) : (
        <Table colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th px={['4', '6', '6']} color="gray.300" width="8">
                <Checkbox colorScheme="pink" />
              </Th>
              <Th>Produto</Th>
              <Th>Quantidade</Th>
              <Th>Categoria</Th>
              <Th>Preço</Th>
              <Th width="8" />
              <Th width="8" />
            </Tr>
          </Thead>
          <Tbody>
            {filteredRawMaterial.map((material: RawMaterial) => {
              return (
                <Tr key={material.id}>
                  <Td px={['4', '4', '6']}>
                    <Checkbox colorScheme="pink" />
                  </Td>
                  <Td>
                    <Box>
                      <Text fontWeight="bold">{material.name}</Text>
                      <Text fontSize="small" color="gray.300">
                        {material.provider.name}
                      </Text>
                    </Box>
                  </Td>

                  <Td>
                    <Tag>
                      {material.amount} {material.measurement_unit}
                    </Tag>
                  </Td>
                  <Td>
                    {(() => {
                      switch (material.category) {
                        case 'TINTASCOLORIDAS':
                          return <span>Tintas Coloridas</span>
                        case 'MANUTENCAO':
                          return <span>Manutenção</span>
                        case 'LIMPEZA':
                          return <span>Limpeza</span>
                        case 'EPI':
                          return <span>Epis</span>
                        case 'PRODUCAO':
                          return <span>Produção</span>
                      }
                    })()}
                  </Td>
                  <Td>
                    <CurrencyFormat
                      displayType="text"
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix="R$ "
                      value={material.price}
                    />
                  </Td>
                  <Td>
                    <Button
                      as={Link}
                      to={`/raw-materials/edit/${material.id}`}
                      size="sm"
                      fontSize="sm"
                      colorScheme="purple"
                      leftIcon={<Icon as={RiPencilLine} />}>
                      Editar
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      onClick={() => handleModalOpen(material)}
                      size="sm"
                      fontSize="sm"
                      colorScheme="red"
                      leftIcon={<Icon as={RiDeleteBin6Line} />}>
                      Remover
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}

      <Pagination totalCountOfRegisters={data?.totalCount} currentPage={page} onPageChange={setPage} />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Remover Produto"
        buttonTitle="Remover"
        buttonAction={handleRemoveButton}>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Nome: ${currentRawMaterial?.name}`}
          </ListItem>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Preço: R$ ${currentRawMaterial?.price}`}
          </ListItem>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Quantidade: ${currentRawMaterial?.amount}`}
          </ListItem>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Categoria: ${currentRawMaterial?.category}`}
          </ListItem>
        </List>
      </Modal>
    </Box>
  )
}
