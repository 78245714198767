import {
  Box,
  Button,
  Checkbox,
  Text,
  Table,
  Tbody,
  Td,
  Tr,
  Th,
  Thead,
  Tooltip,
  IconButton,
  Icon,
  useDisclosure,
  List as RemoveList,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { useState } from 'react';
import { RiCheckLine, RiDeleteBin6Line, RiPencilLine } from 'react-icons/ri';
import { Modal } from '../../components/Modal';
import { Product } from '../../Interfaces/products';

interface Props {
  products: Product[];
  handleAction: (product: any) => void;
}

export function List({ products, handleAction }: Props) {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Quantidade</Th>
            <Th>Cor</Th>
            <Th>Entrega</Th>
            <Th>Etapa</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {products?.map((product) => {
            return (
              <Tr key={product.id}>
                <Td>{product.description}</Td>

                <Td>{product.amount}</Td>
                <Td>{product.ink_color.name}</Td>
                <Td>{format(parseISO(product.delivery_date), 'dd/MM/yyyy')}</Td>
                <Td>{product.step}</Td>
                <Td display="grid" gridGap="2" gridTemplateColumns="1fr 1fr 1fr">
                  <Tooltip label="Editar" placement="top">
                    <IconButton
                      onClick={() => handleAction(product)}
                      size="md"
                      fontSize="sm"
                      colorScheme="purple"
                      aria-label="Editar"
                      icon={<Icon as={RiPencilLine} />}
                    />
                  </Tooltip>
                  <Tooltip label="Remover" placement="top">
                    <IconButton
                      onClick={() => handleAction({ ...product, deleted: true })}
                      size="md"
                      fontSize="sm"
                      colorScheme="red"
                      aria-label="Remover"
                      icon={<Icon as={RiDeleteBin6Line} />}
                    />
                  </Tooltip>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}
