import { Flex, SimpleGrid, Box, VStack, Heading, Tag, HStack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Select } from '../../components/Form/Select'
import { api } from '../../services/api'
import { months } from '../../utils/months'
import { Dashboard as Dash } from '../../Interfaces/dashboard'
import { getYear } from 'date-fns'
import CurrencyFormat from 'react-currency-format'

export default function Dashboard() {
  const { register, handleSubmit, formState, reset } = useForm()

  const [data, setData] = useState<Dash>()
  const [currentMonth, setCurrentMonth] = useState('')

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('month-report', {
        params: {
          date: new Date(),
        },
      })

      setData(data)
    })()
  }, [])

  const handleDate: SubmitHandler<any> = async (values) => {
    const fomatedDate = `${getYear(new Date())}-${values}-01`

    setCurrentMonth(months.find((m) => m.value === values).label)

    const { data } = await api.get('month-report', {
      params: {
        date: fomatedDate,
      },
    })

    setData(data)
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8">
      <Flex mb="8" justifyContent="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Relatórios - {currentMonth}
        </Heading>
      </Flex>
      <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form">
        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
            <Select
              label="Mês"
              {...register('date')}
              options={months}
              placeholder="Selecione o mês que deseja"
              onChange={(e) => {
                handleDate(e.target.value)
              }}
            />
          </SimpleGrid>
        </VStack>
        <Box flex="1" borderRadius="8" bg="gray.800" p="8">
          <HStack spacing="8" justifyContent="space-between">
            <Box width="100%" flexDirection="column" alignItems="center" bg="pink.500" p={5} flex="1">
              <Text fontSize={20} textAlign="center">
                Despesas R${' '}
              </Text>
              <Text fontSize={60} textAlign="center">
                <CurrencyFormat
                  displayType="text"
                  decimalSeparator="."
                  thousandSeparator=","
                  value={data?.totalCost?.toFixed(2) || 0}
                />
              </Text>
            </Box>
            <Box width="100%" flexDirection="column" alignItems="center" bg="pink.500" p={5} flex="1">
              <Text fontSize={20} textAlign="center">
                Receitas R${' '}
              </Text>
              <Text fontSize={60} textAlign="center">
                <CurrencyFormat
                  displayType="text"
                  decimalSeparator="."
                  thousandSeparator=","
                  value={data?.totalRecipe?.toFixed(2) || 0}
                />
              </Text>
            </Box>
            <Box width="100%" flexDirection="column" alignItems="center" bg="pink.500" p={5} flex="1">
              <Text fontSize={20} textAlign="center">
                Lucros R${' '}
              </Text>
              <Text fontSize={60} textAlign="center">
                <CurrencyFormat
                  displayType="text"
                  decimalSeparator="."
                  thousandSeparator=","
                  value={data?.gain?.toFixed(2) || 0}
                />
              </Text>
            </Box>
          </HStack>
        </Box>
      </Box>
    </Box>
  )
}
