import { Button, Flex, HStack, useToast, VStack } from '@chakra-ui/react'
import { Input } from '../../../components/Form/Input'
import { useForm, FieldError } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { RiSave2Line } from 'react-icons/ri'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../../../services/api'

const editPasswordSchema = yup.object().shape({
  oldPass: yup.string().required('Senha antiga é obrigatoria'),
  newPass: yup.string().required('Nova senha é obrigatoria'),
  secondPass: yup
    .string()
    .oneOf([yup.ref('newPass'), null], 'Confirmação não é igual a nova senha')
    .required('Confirmação de senha é obrigatorio'),
})

export default function EditPassword() {
  const toast = useToast()

  const queryClient = useQueryClient()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(editPasswordSchema),
  })

  const errors = formState.errors

  const handleEditPassword = async (data: { oldPass: string; newPass: string; secondPass: string }) => {
    await updatePassword.mutateAsync({ oldPassword: data.oldPass, password: data.newPass })
  }

  const updatePassword = useMutation(
    async (obj: { oldPassword: string; password: string }) => {
      const response = await api.post(`/reset`, { ...obj })

      if (response) {
        toast({ description: 'Senha atualizado com sucesso', status: 'success' })
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
      },
      onError: ({ response }) => {
        if (response.data.errors) {
          const errorList = response.data.errors

          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: response.data.error, status: 'error' })
        }
      },
    },
  )

  return (
    <>
      <VStack spacing="4" align="stretch" as="form" onSubmit={handleSubmit(handleEditPassword)}>
        <HStack spacing="4">
          <Input type="password" label="Senha antiga" {...register('oldPass')} error={errors.oldPass as FieldError} />
          <Input type="password" label="Nova senha" {...register('newPass')} error={errors.newPass as FieldError} />
          <Input
            type="password"
            label="Confirmação de senha"
            {...register('secondPass')}
            error={errors.secondPass as FieldError}
          />
        </HStack>
        <Flex mt="8" justify="flex-end">
          <Button
            type="submit"
            leftIcon={<RiSave2Line size={20} />}
            colorScheme="pink"
            isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}
