import { Flex, Button, Stack, Grid, Box, useToast } from '@chakra-ui/react'
import { Input } from '../../../components/Form/Input'
import { useHistory, useParams } from 'react-router-dom'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldError, useForm } from 'react-hook-form'
import { api } from '../../../services/api'
import { useMutation } from 'react-query'
import { Props } from 'framer-motion/types/types'

export default function TokenPassword() {
  const history = useHistory()

  const toast = useToast()

  const { token }: { token: string } = useParams()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        newPass: yup.string().required('Campo senha obrigatório'),
        secondPass: yup.string().required('Campo senha obrigatório'),
      }),
    ),
  })

  const errors = formState.errors

  const updatePassword = useMutation(
    async (data: string) => {
      const response = await api.post('resetpassword/update', {
        token,
        newPasswords: data,
      })
      if (response) {
        toast({ description: 'Senha alterada com sucesso' })
      }
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleSignIn = (values) => {
    if (values.newPass === values.secondPass) {
      updatePassword.mutateAsync(values)

      history.push('/')
    } else {
      toast({ description: 'Senha de confirmação deve ser igual a nova senha.', status: 'warning' })
    }
  }

  return (
    <Flex h="100vh" justifyContent="center" alignItems="center" verticalAlign="middle">
      <Box borderRadius="8" bg="gray.800" p="8" w="25rem">
        <Grid gridTemplateColumns="1fr">
          <Flex as="form" flexDir="column" onSubmit={handleSubmit(handleSignIn)}>
            <Stack>
              <Input
                name="newPass"
                type="password"
                label="Nova Senha"
                {...(register('newPass') as Props)}
                error={errors.newPass as FieldError}
              />
              <Input
                name="confirmwPass"
                type="password"
                label="Confimação de Senha"
                {...(register('secondPass') as Props)}
                error={errors.secondPass as FieldError}
              />
            </Stack>

            <Button type="submit" mt="6" colorScheme="green" size="lg">
              Gravar
            </Button>
          </Flex>
        </Grid>
      </Box>
    </Flex>
  )
}
