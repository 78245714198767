import { cloneElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

export function ActiveLink({ children, ...rest }: any) {
  const { pathname } = useLocation();

  let isActive = false;

  if (pathname === rest.to) {
    isActive = true;
  }

  return (
    <Link {...rest}>
      {cloneElement(children, {
        color: isActive ? 'pink.400' : 'gray.50',
      })}
    </Link>
  );
}
