import { useQuery } from 'react-query';
import { Client } from '../../Interfaces/client';
import { api } from '../api';

type GetClientsResponse<T> = {
  totalCount?: number;
  clients: T;
};

type QueryClientType = {
  page?: number;
  isSelect?: boolean;
  name?: string;
  cnpj?: string;
  email?: string;
  staleTime?: boolean;
};

export async function getClients<T>(params: QueryClientType): Promise<GetClientsResponse<T>> {
  const { isSelect } = params;

  let { data } = await api.get('clients', {
    params,
  });

  if (isSelect) {
    data = data.map((x: Client) => ({
      label: x.name,
      value: x.id,
    }));

    return {
      clients: data,
    };
  }

  return {
    clients: data.data,
    totalCount: data.meta.total,
  };
}

export function useClients<T>(params: QueryClientType) {
  let options = {};

  if (params.staleTime) {
    options = { staleTime: 5000 };
  } else {
    options = {
      retry: false,
    };
  }

  return useQuery<GetClientsResponse<T>>(['clients', params], () => getClients<T>(params), options);
}
