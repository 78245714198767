import { Flex, Box, Text, Avatar, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { RiLogoutBoxRLine, RiSettings3Fill, RiUserLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { ProfileMenuItem } from '../MenuItem';
import { useContext } from 'react';

interface ProfileProps {
  showProfileData?: boolean;
}

export function Profile({ showProfileData = true }: ProfileProps) {
  const history = useHistory();

  const { signOut, user } = useContext(AuthContext);

  const handleLogout = async () => {
    await signOut();

    history.push('/');
  };

  return (
    <Menu>
      <Flex align="center">
        <>
          {showProfileData && (
            <Box mr="4" textAlign="right">
              <Text>{user?.name}</Text>
              <Text color="gray.300" fontSize="small">
                {user?.email}
              </Text>
            </Box>
          )}
          <MenuButton>
            <Avatar size="md" name={user?.name} />
          </MenuButton>
        </>
      </Flex>

      <MenuList bg="gray.900" borderColor="gray.700">
        <ProfileMenuItem icon={<RiUserLine size="20" />} onClick={() => history.push(`/user-profile/${user?.id}`)}>
          Perfil
        </ProfileMenuItem>
        <ProfileMenuItem icon={<RiSettings3Fill size="20" />} onClick={() => history.push(`/configurations`)}>
          Configurações
        </ProfileMenuItem>
        <ProfileMenuItem icon={<RiLogoutBoxRLine size="20" />} onClick={handleLogout}>
          Sair
        </ProfileMenuItem>
      </MenuList>
    </Menu>
  );
}
