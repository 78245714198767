import { Flex, Button, useToast, Box, VStack, SimpleGrid, HStack } from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { api } from '../../../services/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Demand } from '../../../Interfaces/demands';
import { Select } from '../../../components/Form/Select';
import { useClients } from '../../../services/hooks/useClients';
import { Client } from '../../../Interfaces/client';
import { useEffect } from 'react';

const createDemandsSchema = yup.object().shape({
  client_id: yup.string().required('Selecione um cliente'),
});

interface Props {
  currendDemand?: Demand;
  onClose: () => void;
}

export default function DemandsCreateOrUpdate({ currendDemand, onClose }: Props) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const toast = useToast();

  const id = currendDemand?.id ?? '';

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createDemandsSchema),
  });

  const { data } = useClients<Client[]>({
    isSelect: true,
    staleTime: false,
  });

  useEffect(() => {
    if (id) {
      setValue('client_id', currendDemand.client.id);
    }
  }, [data]);

  const isCreateMode = !id;

  const createDemand = useMutation(
    async (demand: Demand) => {
      const response = await api.post<Demand>('demands', { ...demand });
      if (response) {
        toast({ description: 'Cadastrado com sucesso.' });

        history.push(`/demands/${response.data.id}/services_order`);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('demands');
      },
      onError: ({ response }) => {
        const errorList = response.data.errors;

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }));
        toastOptions.forEach((t) => {
          toast(t);
        });
      },
    },
  );

  const updateDemands = useMutation(
    async (demand: Demand) => {
      const response = await api.put(`demands/${id}`, { ...demand, status: currendDemand.status });
      if (response) {
        toast({ description: 'Editado com sucesso.' });
        onClose();
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('demands');
      },
      onError: ({ response }) => {
        const errorList = response.data.errors;

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }));
        toastOptions.forEach((t) => {
          toast(t);
        });
      },
    },
  );

  const errors = formState.errors;

  const handleCreateOrUpdate: SubmitHandler<Demand> = async (values) => {
    isCreateMode ? await createDemand.mutateAsync(values) : await updateDemands.mutateAsync(values);
  };

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Select
            label="Nome do cliente"
            {...register('client_id')}
            options={data?.clients}
            placeholder="Selecione o cliente"
          />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  );
}
