import { useQuery } from 'react-query'
import { DemandStatus } from '../../Enuns/demandStatus.enum'
import { Demand } from '../../Interfaces/demands'
import { api } from '../api'

type GetDemandsResponse = {
  totalCount: number
  demands: Demand[]
}

type QueryDemandType = {
  page?: number
  name?: string
  username?: string
  email?: string
  notInStatus?: DemandStatus
  status?: DemandStatus
  demandId?: number
  serviceOrderId?: number
}

export async function getDemands(
  params: QueryDemandType,
  start_date: string,
  end_date: string,
): Promise<GetDemandsResponse> {
  const { data } = await api.get('demands', {
    params: {
      ...params,
      start_date,
      end_date,
    },
  })

  return {
    demands: data.data,
    totalCount: data.meta.total,
  }
}

export function useDemands(params: QueryDemandType, start_date: string, end_date: string) {
  return useQuery(['demands', params, start_date, end_date], () => getDemands(params, start_date, end_date), {
    staleTime: 5000,
  })
}
