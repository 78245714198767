import { Button, Flex, HStack, useToast, VStack } from '@chakra-ui/react'
import { Input } from '../../../components/Form/Input'
import { useForm, FieldError } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Access } from '../../../Enuns/user.enum'
import { useEffect } from 'react'
import { RiEditLine } from 'react-icons/ri'
import { api } from '../../../services/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { User } from '../../../Interfaces/users'

const editUserSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  username: yup.string().required('Nome de usuário obrigatório'),
  email: yup.string().email('Necessário email válido').required('Campo email obrigatório'),
  access: yup.string(),
})

interface Props {
  id: string
}

export default function EditUser({ id }: Props) {
  const toast = useToast()

  const queryClient = useQueryClient()

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(editUserSchema),
  })

  const errors = formState.errors

  if (id) {
    useQuery('user', async () => {
      const { data } = await api.get<User>(`users/${id}`)

      reset({
        ...data,
        access: Object.keys(Access).includes(data.access) ? Access[data.access] : data.access,
      })
    })
  }

  const updateUser = useMutation(
    async (user: User) => {
      const response = await api.put(`users/${id}`, { ...user })

      if (response) {
        toast({ description: 'Usuário atualizado com sucesso', status: 'success' })
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleEditUser = async (data) => {
    await updateUser.mutateAsync(data)
  }

  return (
    <>
      <VStack spacing="4" align="stretch" as="form" onSubmit={handleSubmit(handleEditUser)}>
        <HStack spacing="4">
          <Input label="Nome" {...register('name')} error={errors.name as FieldError} />
          <Input label="Nome de usuário" {...register('username')} error={errors.username as FieldError} />
        </HStack>

        <HStack spacing="4">
          <Input label="Email" {...register('email')} error={errors.email as FieldError} />
          <Input label="Permissão" isDisabled {...register('access')} error={errors.access as FieldError} />
        </HStack>
        <Flex mt="8" justify="flex-end">
          <Button
            type="submit"
            leftIcon={<RiEditLine size={20} />}
            colorScheme="pink"
            isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </Flex>
      </VStack>
    </>
  )
}
