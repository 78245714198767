import { useEffect } from 'react';
import { useState } from 'react';
import { createContext, ReactNode } from 'react';
import { api } from '../services/api';
import { Configuration } from '../Interfaces/configuration';
import { cookies } from './AuthContext';

type ConfigurationData = {
  configuration: Configuration;
  notifications: [];
  populateNotifications: (notifications) => void;
};

type Props = {
  children: ReactNode;
};

export const ConfigurationContext = createContext({} as ConfigurationData);

export const ConfigurationProvider = ({ children }: Props) => {
  const [configuration, setConfiguration] = useState<Configuration>();
  const [notifications, setNotifications] = useState<[]>([]);

  useEffect(() => {
    if (cookies.get('@AXAuth:User')) {
      if (!configuration) loadConfiguration();
    }
  }, [configuration]);

  const populateNotifications = (notifications) => {
    setNotifications(notifications);
  };

  const loadConfiguration = async () => {
    try {
      const { data } = await api.get(`configurations/last-update`);

      setConfiguration(data && data[0]);
    } catch (err) {
      console.error('Servidor não encontrado');
    }
  };

  return (
    <ConfigurationContext.Provider value={{ configuration, notifications, populateNotifications }}>
      {children}
    </ConfigurationContext.Provider>
  );
};
