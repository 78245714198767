import { Flex, Button, useToast, Box, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { api } from '../../../../services/api'
import { SubmitHandler, useForm, FieldError } from 'react-hook-form'
import { Demand } from '../../../../Interfaces/demands'
import { Select } from '../../../../components/Form/Select'
import { ServiceOrders } from '../../../../Interfaces/serviceOrders'
import { Input } from '../../../../components/Form/Input'
import { accessOptions } from '../../../../utils/billingForms'
import { RawMaterial } from '../../../../Interfaces/rawMaterial'
import { ovenSapace } from '../../../../utils/ovenSapace'
import InputMask from 'react-input-mask'
import { format } from 'date-fns'
import { Product } from '../../../../Interfaces/products'
import { stepOptions } from '../../../../utils/serviceOrderSteps'

const createServiceOrderSchema = yup.object().shape({
  delivery_date: yup.string().required('Informe a data de entrega'),
  description: yup.string().required('Informe o nome do produto'),
  amount: yup.string().required('Informe a quantidade'),
  ink_color_id: yup.string().required('Informe a cor'),
  price: yup.string().required('Informe o preço'),
  billing_method: yup.string().required('Informe forma de cobrança'),
  oven_space: yup.number().required('Informe queimada'),
})

interface Props {
  currendDemand?: Demand
  onClose: () => void
}

export default function ServiceCreateOrUpdate({ currendDemand, onClose }: Props) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const toast = useToast()

  const id = currendDemand?.id ?? ''

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(createServiceOrderSchema),
  })

  const errors = formState.errors

  const { data: colors } = useQuery(
    'colors',
    async () => {
      const response = await api.get<RawMaterial[]>(`raw-materials`, {
        params: {
          category: 'TINTASCOLORIDAS',
        },
      })

      const select = response.data.map((x: RawMaterial) => ({
        label: `${x.name} - ${x.amount}KG`,
        value: x.id,
      }))

      return {
        colors: select,
      }
    },
    {
      retry: false,
    },
  )

  const isCreateMode = !id

  const createDemand = useMutation(
    async (product: Product) => {
      const splitDate = product.delivery_date.split('/')

      const newDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`

      const products = [{ ...product, delivery_date: format(new Date(newDate), 'yyyy-MM-dd') }]

      const response = await api.post<Product>(`/demands/${currendDemand.id}/service-orders`, {
        products,
      })
      if (response) {
        toast({ description: 'Cadastrado com sucesso.' })

        history.push(`/demands/${currendDemand.id}/services_order/${response.data.id}`)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('demands')
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao cadastrar', status: 'error' })
        }
      },
    },
  )

  const updateDemands = useMutation(
    async (demand: ServiceOrders) => {
      const response = await api.put(`service_orders/${id}`, { ...demand, status: currendDemand.status })
      if (response) {
        toast({ description: 'Editado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('demands')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleCreateOrUpdate: SubmitHandler<Product> = async (values) => {
    await createDemand.mutateAsync(values)
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="Data da Entrega"
            {...register('delivery_date')}
            error={errors.delivery_date as FieldError}
            as={InputMask}
            mask="99/99/9999"
          />
          <Input label="Nome do produto" {...register('description')} error={errors.description as FieldError} />
          <Input label="Quantidade" {...register('amount')} error={errors.amount as FieldError} />
          <Select label="Cores" {...register('ink_color_id')} options={colors?.colors} placeholder="Selecione um cor" />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Valor" {...register('price')} error={errors.price as FieldError} />
          <Select
            label="Forma de cobrança"
            {...register('billing_method')}
            options={accessOptions}
            placeholder="Selecione"
          />

          <Select label="Queimada" {...register('oven_space')} options={ovenSapace} placeholder="Selecione" />
          <Select label="Etapa" {...register('step')} options={stepOptions} placeholder="Selecione" />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
