export const accessOptions = [
  {
    label: 'KG',
    value: 'KG',
  },
  {
    label: 'M2',
    value: 'M2',
  },
  {
    label: 'Metro Linear',
    value: 'M',
  },
  {
    label: 'Peça',
    value: 'PECA',
  },
  {
    label: 'Hora',
    value: 'HORA',
  },
]
