import { Flex, Button, useToast, Box, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Input } from '../../../components/Form/Input'
import { api } from '../../../services/api'
import { SubmitHandler, useForm, FieldError } from 'react-hook-form'
import { Select } from '../../../components/Form/Select'
import InputMask from 'react-input-mask'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Recipes } from '../../../Interfaces/recipes'

const criarReceitaSchema = yup.object().shape({
  description: yup.string().required('Nome da receita é obrigatório'),
  type: yup.string().required('Tipo da receita é obrigatório'),
  category: yup.string().required('Categoria da receita é obrigatório'),
  value: yup.string().required('Valor da receita é obrigatório'),
  receipt_status: yup.string().required('Situação da receita é obrigatório'),
  receipt_date: yup.string(),
})

interface Props {
  recipes?: Recipes
  onClose: () => void
}

export default function RecipesCreateOrUpdate({ recipes, onClose }: Props) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const toast = useToast()
  const [receiptStatusValid, setReceiptStatusValid] = useState(false)

  const situacaoReceitaOpcoes = [
    {
      label: 'Recebido',
      value: 'RECEBIDO',
    },
    {
      label: 'Pendente',
      value: 'PENDENTE',
    },
  ]

  const receitaOpcoes = [
    {
      label: 'Fixo',
      value: 'FIXO',
    },
    {
      label: 'Variável',
      value: 'VARIAVEL',
    },
  ]

  const categoriasDespesa = [
    {
      label: 'Empresa',
      value: 'EMPRESA',
    },
    {
      label: 'Pessoal',
      value: 'PESSOAL',
    },
    {
      label: 'Outros',
      value: 'OUTROS',
    },
  ]

  const id = recipes?.id ?? ''

  const isCreateMode = !id

  const criarReceita = useMutation(
    async (recipe: Recipes) => {
      const date = receiptStatusValid ? recipe.receipt_date.split('/') : null

      const response = await api.post('recipes', {
        ...recipe,
        receipt_date:
          recipe.receipt_status === 'RECEBIDO'
            ? format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd')
            : null,
      })
      if (response) {
        toast({ description: 'Cadastrado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('recipes')
      },
      onError: ({ response }) => {
        const errorList = response && response.data.errors
        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        }
      },
    },
  )

  const updateUsers = useMutation(
    async (recipe: Recipes) => {
      const date = receiptStatusValid ? recipe.receipt_date.split('/') : null
      const response = await api.put(`recipes/${id}`, {
        ...recipe,
        receipt_date: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
      })
      if (response) {
        toast({ description: 'Editado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('recipes')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const { register, handleSubmit, formState, reset, getValues, watch } = useForm({
    resolver: yupResolver(criarReceitaSchema),
  })

  const receipt_status = watch('receipt_status')

  const errors = formState.errors

  const handleCreateOrUpdate: SubmitHandler<Recipes> = async (values) => {
    isCreateMode ? await criarReceita.mutateAsync(values) : await updateUsers.mutateAsync(values)

    history.push('/revenues')
  }

  useEffect(() => {
    if (id) {
      reset({
        ...recipes,
        receipt_date: recipes.receipt_date ? format(new Date(recipes.receipt_date), 'dd/MM/yyyy') : '',
      })
    }
  }, [])

  useEffect(() => {
    console.log(getValues())
  }, [getValues()])

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Nome" {...register('description')} error={errors.description as FieldError} />
          <Select
            label="Situação do recebimento"
            {...register('receipt_status')}
            error={errors.receipt_status as FieldError}
            onChange={(e) =>
              e.target.value === 'RECEBIDO' ? setReceiptStatusValid(true) : setReceiptStatusValid(false)
            }
            options={situacaoReceitaOpcoes}
            placeholder="Situação da Receita"
          />
          <Select
            label="Tipo da receita"
            error={errors.type as FieldError}
            {...register('type')}
            options={receitaOpcoes}
            placeholder="Tipo da despesa"
          />
          <Select
            label="Categoria da despesa"
            {...register('category')}
            options={categoriasDespesa}
            error={errors.category as FieldError}
            placeholder="Categoria"
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Valor" {...register('value')} error={errors.value as FieldError} />
          {receiptStatusValid ? (
            <Input
              label="Data do recebimento"
              {...register('receipt_date')}
              error={errors.receipt_date as FieldError}
              as={InputMask}
              defaultValue={!id && String(format(new Date(), 'dd/MM/yyyy'))}
              mask="99/99/9999"
            />
          ) : (
            ''
          )}
          <small> (Deve ser cadastradada com o mês atual para ser listada)</small>
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button as="a" onClick={() => onClose()} colorScheme="whiteAlpha">
            Cancelar
          </Button>
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
