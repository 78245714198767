import { useQuery } from 'react-query'
import { Costs } from '../../Interfaces/costs'
import { api } from '../api'

type GetCostsResponse = {
  costs: Costs[]
}

export async function getCosts(start_date: string, end_date: string): Promise<GetCostsResponse> {
  const { data } = await api.get('costs', {
    params: {
      start_date,
      end_date,
    },
  })

  return {
    costs: data,
  }
}

export function useCosts(start_date: string, end_date: string) {
  return useQuery(['costs', start_date, end_date], () => getCosts(start_date, end_date), {
    staleTime: 5000,
  })
}
