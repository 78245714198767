import { useEffect } from 'react';
import { useState } from 'react';
import { createContext, ReactNode } from 'react';
import { api } from '../services/api';
import Cookies from 'universal-cookie';
import { CookieInfo } from '../Enuns/cookie.enum';
import { useToast } from '@chakra-ui/react';
import { User } from '../Interfaces/users';

type Credentials = {
  username: string;
  password: string;
};

type AuthContextValues = {
  signOut: () => void;
  signIn: (credentials: Credentials) => Promise<boolean>;
  isAuthenticated: boolean;
  user: User | undefined;
};

type Props = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextValues);

export const cookies = new Cookies();

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User>();

  const toast = useToast();

  useEffect(() => {
    if (cookies && cookies.get('@AXAuth:User')) setUser(cookies.get('@AXAuth:User'));
  }, []);

  const signIn = async ({ username, password }: Credentials) => {
    try {
      const response = await api.post('/login', {
        username,
        password,
      });

      const currentUser = response.data.user;

      const token = response.data.token.token;

      setUser(currentUser);

      cookies.set('@AXAuth:Token', token, { path: '/', maxAge: CookieInfo.MAX_AGE });

      cookies.set('@AXAuth:User', currentUser, {
        path: '/',
        maxAge: CookieInfo.MAX_AGE,
      });

      api.defaults.headers['Authorization'] = `Bearer ${token}`;

      return true;
    } catch (err) {
      if (err.response) toast({ description: err.response.data.error, status: 'error' });

      return false;
    }
  };

  const signOut = async () => {
    await api.post('/logout');

    setUser(null);

    cookies.remove('@AXAuth:Token', { path: '/' });

    cookies.remove('@AXAuth:User', { path: '/' });
  };

  return (
    <AuthContext.Provider value={{ signOut, signIn, isAuthenticated: !!user, user }}>{children}</AuthContext.Provider>
  );
};
