import {
  FormLabel,
  FormControl,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  FormErrorMessage,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError } from 'react-hook-form';
import { SelectOptions } from '../../Interfaces/selectOptions';

interface Props extends ChakraSelectProps {
  name: string;
  label: string;
  error?: FieldError;
  options: SelectOptions[] | any;
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  { name, label, error, options, ...rest }: Props,
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={label}>{label}</FormLabel>}
      <ChakraSelect
        name={name}
        id={name}
        focusBorderColor="pink.500"
        bg="white"
        color="blackAlpha.900"
        colorScheme="gray"
        variant="outline"
        ref={ref}
        size="lg"
        {...rest}
      >
        {options?.map((x) => {
          return (
            <option key={x.value} value={x.value}>
              {x.label}
            </option>
          );
        })}
      </ChakraSelect>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Select = forwardRef(SelectBase);
