import { Flex, Button, useToast, Box, Heading, Divider, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Input } from '../../../components/Form/Input'
import { api } from '../../../services/api'
import { SubmitHandler, useForm, FieldError } from 'react-hook-form'
import { User } from '../../../Interfaces/users'
import { Select } from '../../../components/Form/Select'
import { Access } from '../../../Enuns/access.enum'
import { Link } from 'react-router-dom'

const createUsersSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().required('E-mail obrigatório'),
  username: yup.string().required('Login obrigatório'),
  access: yup.string().required('Acesso obrigatório'),
})

const accessOptions = Object.entries(Access).map((cat) => ({
  label: cat[1],
  value: cat[0],
}))

export default function UsersCreateOrUpdate({ match }: any) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { id } = match.params

  const isCreateMode = !id

  const createUser = useMutation(
    async (user: User) => {
      const response = await api.post('users', { ...user })
      if (response) {
        toast({ description: 'Cadastrado com sucesso.' })
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const updateUsers = useMutation(
    async (user: User) => {
      const response = await api.put(`users/${id}`, { ...user })
      if (response) {
        toast({ description: 'Editado com sucesso.' })
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(createUsersSchema),
  })

  if (id) {
    useQuery(
      'user',
      async () => {
        const { data } = await api.get(`users/${id}`)
        reset(data)
      },
      {
        staleTime: 5000,
      },
    )
  }

  const errors = formState.errors

  const handleCreateOrUpdate: SubmitHandler<User> = async (values) => {
    isCreateMode ? await createUser.mutateAsync(values) : await updateUsers.mutateAsync(values)

    history.push('/users')
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <Heading size="lg" fontWeight="normal">
        {isCreateMode ? 'Novo Usuário' : 'Editar Usuário'}
      </Heading>
      <Divider my="6" borderColor="gray.700" />

      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Nome" {...register('name')} error={errors.name as FieldError} />
          <Input label="E-mail" {...register('email')} error={errors.email as FieldError} />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Senha" {...register('password')} error={errors.password as FieldError} />
          <Input label="Nome de usuário" {...register('username')} error={errors.username as FieldError} />
          <Select label="Acesso" {...register('access')} options={accessOptions} placeholder="Perfil de acesso" />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button as={Link} to={`/providers`} colorScheme="whiteAlpha">
            Cancelar
          </Button>
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
