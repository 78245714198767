import { Flex, Button, useToast, Box, Heading, Divider, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'

import { Configuration } from '../../Interfaces/configuration'
import { api } from '../../services/api'
import { Input } from '../../components/Form/Input'

const createConfigurationsSchema = yup.object().shape({
  max_order_days: yup.number().positive().notRequired().nullable(),
  oven_burn_value: yup.number().positive().notRequired().nullable(),
  oven_parts: yup.number().positive().notRequired().nullable(),
  work_hour_value: yup.number().positive().notRequired().nullable(),
})

export default function ConfigurationForm() {
  const history = useHistory()

  const queryClient = useQueryClient()

  const toast = useToast()

  const createConfiguration = useMutation(
    async (config: Configuration) => {
      const response = await api.post('configurations', {
        max_order_days: config.max_order_days,
        oven_burn_value: config.oven_burn_value,
        oven_parts: config.oven_parts,
        work_hour_value: config.work_hour_value,
      })

      if (response) toast({ description: 'Cadastrado com sucesso.' })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('configurations')
      },
      onError: ({ response }) => {
        const toastOptions = response.data.errors.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const updateConfiguration = useMutation(
    async (config: Configuration) => {
      const response = await api.put(`configurations/${config.id}`, {
        max_order_days: config.max_order_days,
        oven_burn_value: config.oven_burn_value,
        oven_parts: config.oven_parts,
        work_hour_value: config.work_hour_value,
      })

      if (response) toast({ description: 'Cadastrado com sucesso.' })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('configurations')
      },
      onError: ({ response }) => {
        const toastOptions = response.data.errors.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(createConfigurationsSchema),
  })

  useQuery(
    'configurations',
    async () => {
      const { data } = await api.get(`configurations/last-update`)

      data.length ? reset(data[0]) : reset({ max_order_days: 0, oven_burn_value: 0, oven_parts: 0, work_hour_value: 0 })
    },
    { staleTime: 5000 },
  )

  const errors = formState.errors

  const handleCreateClient: SubmitHandler<Configuration> = async (values) => {
    const { data } = await api.get(`configurations/last-update`)

    data.length === 0 ? await createConfiguration.mutateAsync(values) : await updateConfiguration.mutateAsync(values)

    history.goBack()
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateClient)}>
      <Heading size="lg" fontWeight="normal">
        Editar Configurações
      </Heading>

      <Divider my="6" borderColor="gray.700" />

      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="Limite de dias de um pedido: "
            type="number"
            {...register('max_order_days')}
            error={errors.max_order_days as FieldError}
          />

          <Input
            label="Valor de queimada no forno: "
            type="number"
            {...register('oven_burn_value')}
            error={errors.oven_burn_value as FieldError}
          />

          <Input
            label="Total de partes do forno: "
            type="number"
            {...register('oven_parts')}
            error={errors.oven_parts as FieldError}
          />

          <Input
            label="Valor de hora trabalhada"
            type="number"
            {...register('work_hour_value')}
            error={errors.work_hour_value as FieldError}
          />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button onClick={() => history.goBack()} colorScheme="whiteAlpha">
            Voltar
          </Button>
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
