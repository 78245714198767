import { Flex, IconButton, useBreakpointValue, Icon } from '@chakra-ui/react'
import { useContext } from 'react'
import { RiMenuLine } from 'react-icons/ri'
import { SearchContext } from '../../context/SearchContext'
import { useSidebarDrawer } from '../../context/SidebarDrawer'
import { Logo } from './Logo'
import { NotificationsNav } from './NotificationsNav'
import { Profile } from './Profile'
import { Search } from './Search'

export function Header() {
  const { onOpen } = useSidebarDrawer()

  const { isShortScreenOpen } = useContext(SearchContext)

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex as="header" w="100%" maxWidth={1700} h="20" mx="auto" mt="4" px="6" align="center">
      {!isWideVersion && (
        <IconButton
          aria-label="Open Navigation"
          icon={<Icon as={RiMenuLine} />}
          onClick={onOpen}
          fontSize="24"
          variant="unstyled"
          mr="2"
        />
      )}
      <Logo />

      <Search isIconVersion={!isWideVersion} />

      {!isShortScreenOpen && (
        <Flex align="center" ml="auto">
          <NotificationsNav />
          <Profile showProfileData={isWideVersion} />
        </Flex>
      )}
    </Flex>
  )
}
